<template>
  <path
    d="M10.0003783,20.3334391 L8.66704496,20.3334391 C8.40971163,20.3334391 8.17504496,20.1847725 8.06437829,19.9521058 C7.95371163,19.7194391 7.98771163,19.4441058 8.15104496,19.2441058 L13.8057116,12.3334391 L8.15104496,5.42210581 C7.98771163,5.22277248 7.95437829,4.94743915 8.06437829,4.71410581 C8.17437829,4.48077248 8.40971163,4.33343915 8.66704496,4.33343915 L10.0003783,4.33343915 C10.2003783,4.33343915 10.3897116,4.42343915 10.5163783,4.57810581 L16.5163783,11.9114391 C16.717045,12.1574391 16.717045,12.5101058 16.5163783,12.7561058 L10.5163783,20.0894391 C10.3897116,20.2434391 10.2003783,20.3334391 10.0003783,20.3334391 Z"
    id="Path"
    fill="#656B80"
    transform="translate(12.333439, 12.333439) rotate(90.000000) translate(-12.333439, -12.333439) "
  ></path>
</template>

<script>
export default {
  name: "ChevronDownIcon"
};
</script>
